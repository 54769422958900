import Home from "./domain/home/home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import VacaturePagina from "./domain/vacatures/vacatures";
import Navbar from "./components/navbar/navbar";
import styled from "styled-components";
import Footer from "./components/footer/footer";
import Rebels from "./domain/rebels/rebels";
import Contact from "./domain/contact/contact";
import "./index.css";
import Blog from "./domain/blog/blog";
import BlogDetail from "./domain/blog/blogDetail";
import ScrollToTop from "./components/navbar/scrollToTop";

const App = () => {
  return (
    <>
      <Test>
        <Router>
          <Navbar />
          <ScrollToTop />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/werken-bij" component={VacaturePagina} />
            <Route path="/over-rebels" component={Rebels} />
            <Route path="/blog/:id" component={BlogDetail} />
            <Route path="/blogs" component={Blog} />
            <Route path="/contact" component={Contact} />
          </Switch>
          <Footer />
        </Router>
      </Test>
    </>
  );
};

const Test = styled.div`
  margin-left: -8px;
  margin-bottom: -8px;
  margin-right: -8px;
  overflow: hidden;
`;

export default App;
