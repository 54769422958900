import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDAhjR_hScd9Z6ACgni9UtCRkvOLt4rVEg",
  authDomain: "rebels-website-16529.firebaseapp.com",
  projectId: "rebels-website-16529",
  storageBucket: "rebels-website-16529.appspot.com",
  messagingSenderId: "991379380045",
  appId: "1:991379380045:web:a0ef616f29e196a2494904",
  measurementId: "G-XFWGNM2M2N",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const db = getFirestore(app);

export default db;
