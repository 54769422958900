import { OFFERS } from "../../services/endpoints";
import rectruiteeAPI from "../../services/rectuiteeApi";
import { RectruiteeItem, vacancies } from "../../typings/recruiteeItem";

export default {
  async retrievePosts(): Promise<RectruiteeItem[] | false> {
    try {
      const response = await rectruiteeAPI().get<vacancies>(`${OFFERS}`);
      return response.data.offers;
    } catch (error) {
      return false;
    }
  },
};
