import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { styleConstants } from "../../assets/style-contstants/style";
import vacatureApi from "./vacatrueApi";

const Vacatures: React.FunctionComponent = () => {
  const vacatureQuery = useQuery("posts", async () => {
    return vacatureApi.retrievePosts();
  });

  const handleRoute = (url: string) => {
    window.open(url);
  };

  const history = useHistory();

  const handleHistory = (url: string) => {
    history.push(url);
  };

  return (
    <>
      <HomeContainer>
        <Title>een nieuwe baan </Title>
        <VacatureWrapper>
          <VactatureContainer>
            {vacatureQuery.data ? (
              vacatureQuery.data.map((post) => {
                return (
                  <VacatureItem onClick={() => handleRoute(post.careers_url)}>
                    <VacatureTitle>{post.title}</VacatureTitle>
                    <ItemWrapper>
                      <VacatureLocatie>{post.location}</VacatureLocatie>
                      <VacatureSector>{post.department}</VacatureSector>
                    </ItemWrapper>
                  </VacatureItem>
                );
              })
            ) : (
              <ErrorMessage>loading</ErrorMessage>
            )}
          </VactatureContainer>
        </VacatureWrapper>

        <Title2>een nieuwe collega </Title2>
        <Wrapper>
          <VacatureItem onClick={() => handleHistory("/contact")}>
            <VacatureTitle>
              Neem contact met ons op om jou nieuwe collega te vinden!
            </VacatureTitle>
            <ItemWrapper>
              <VacatureLocatie>Voor jouw bedrijf</VacatureLocatie>
              <VacatureSector>Contact</VacatureSector>
            </ItemWrapper>
          </VacatureItem>
        </Wrapper>
      </HomeContainer>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 150px;
  @media only screen and (max-width: 1150px) {
    justify-content: center;
    flex-direction: column;
  }
`;

const Title = styled.div`
  font-family: ${styleConstants.fontFamilyHeadingBold};
  font-style: bold;
  font-size: 60px;
  text-align: center;
  margin: 175px 0 30px;
  color: ${styleConstants.colors.seaGreen};
  line-height: 40px;
  @media only screen and (max-width: 850px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 650px) {
    font-size: 40px;
  }
`;

const Title2 = styled.div`
  font-family: ${styleConstants.fontFamilyHeadingBold};
  font-weight: 900;
  font-style: bold;
  font-size: 50px;
  color: ${styleConstants.colors.seaGreen};
  text-align: center;
  margin: 75px 40px;
  margin-bottom: 60px;
  @media only screen and (max-width: 1000px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 850px) {
    font-size: 40px;
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 650px) {
    font-size: 30px;
    margin-bottom: 20px;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const VacatureSector = styled.div`
  font-family: ${styleConstants.fontFamilyHeading};
  color: ${styleConstants.colors.seaGreen};
  font-style: bold;
  font-size: 15px;
  margin-top: 30px;
  font-weight: 500;
  padding: 5px;
  border-radius: 5px;
  background-color: ${styleConstants.colors.lightGreen};
`;

const VacatureItem = styled.div`
  border: 3px solid ${styleConstants.colors.lightGreen};
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 15px;
  column-break-inside: avoid;

  &:hover {
    background-color: ${styleConstants.colors.lightGreen};
    transition: 200ms ease-in;
  }
  &:hover {
    background-color: ${styleConstants.colors.lightGreen};
    ${VacatureSector} {
      background-color: ${styleConstants.colors.salmon};
      transition: 200ms ease-in;
    }
  }
  @media only screen and (max-width: 850px) {
    margin: 15px;
  }
`;

const VacatureLocatie = styled.div`
  font-family: ${styleConstants.fontFamilyHeading};
  color: ${styleConstants.colors.seaGreen};
  font-style: bold;
  font-size: 15px;

  margin-top: 30px;
  font-weight: 500;
  margin-right: 10px;
`;

const VacatureTitle = styled.div`
  font-family: ${styleConstants.fontFamilyHeading};
  color: ${styleConstants.colors.seaGreen};
  font-style: bold;
  font-size: 16px;

  margin-top: 5px;
  font-weight: 800;
`;

const VacatureWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const ErrorMessage = styled.div``;

const VactatureContainer = styled.div`
  column-count: 2;

  justify-content: space-between;
  background-color: ${styleConstants.colors.white};
  flex-wrap: wrap;
  padding: 25px;
  @media only screen and (max-width: 850px) {
    column-count: 1;
  }
`;

const HomeContainer = styled.div`
  background-color: ${styleConstants.colors.white};
  width: 100%;
  margin-bottom: 70px;
  min-height: calc(100vh - 600px);
`;

export default Vacatures;
