import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { styleConstants } from "../../assets/style-contstants/style";
import { BlogItem } from "../../typings/blogItem";
import { useLocation, useHistory } from "react-router-dom";

import { getDocs, collection } from "firebase/firestore";
import db from "../../firebase";

const BlogDetail: React.FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const [blogs, setBlogs] = useState<BlogItem[]>();
  const myparams = location.state as BlogItem;

  const internalRoute = (url: string, data: BlogItem) => {
    history.push({
      pathname: url,
      state: data,
    });
  };

  const fetchBlogs = async () => {
    const querySnapshot = await getDocs(collection(db, "blogs"));
    let temp: any[] = [];
    querySnapshot.forEach((doc) => {
      temp.push(doc.data());
    });

    for (var i = 0; i < temp.length; i++) {
      if (temp[i].id === myparams.id) {
        temp.splice(i, 1);
      }
    }
    setBlogs(temp);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <>
      <PageContainer>
        <BlogContainer>
          <BlogFoto src={myparams.photo} />
          <BlogTitle>{myparams.title}</BlogTitle>
          <BlogCardWrapper>
            {myparams.tags.map((doc) => {
              return <BlogCatagory>{doc}</BlogCatagory>;
            })}
          </BlogCardWrapper>
          <BlogText
            dangerouslySetInnerHTML={{ __html: myparams.text }}
          ></BlogText>

          <Title>gerelateerde blogs</Title>
          <RelativeBlogs>
            {blogs != null ? (
              blogs.slice(0, 5).map((data: BlogItem) => {
                return (
                  <BlogCard
                    key={data.id}
                    onClick={() => internalRoute(`/blog/${data.id}`, data)}
                  >
                    <RelativeBlogFoto src={data.photo} />
                    <RelativeBlogTitle>{data.title}</RelativeBlogTitle>
                  </BlogCard>
                );
              })
            ) : (
              <ErrorMessage>loading</ErrorMessage>
            )}
          </RelativeBlogs>
        </BlogContainer>
      </PageContainer>
    </>
  );
};

const RelativeBlogCatagory = styled.div`
  font-family: ${styleConstants.fontFamilyHeading};
  font-weight: 900;
  font-style: bold;
  font-size: 8px;
  color: ${styleConstants.colors.white};
  margin-left: 5px;
  background-color: ${styleConstants.colors.salmon};
  padding: 5px;
  border-radius: 5px;
`;

const Title = styled.div`
  font-family: ${styleConstants.fontFamilyHeading};
  font-weight: 900;
  font-style: bold;
  font-size: 40px;
  color: ${styleConstants.colors.seaGreen};
  text-align: center;
  margin-bottom: 20px;
  width: 60%;
  @media only screen and (max-width: 650px) {
    font-size: 30px;
  }
  @media only screen and (max-width: 425px) {
    font-size: 25px;
  }
`;

const RelativeBlogFoto = styled.img`
  width: 100%;
  max-height: 100px;
  border-radius: 5px;
`;

const RelativeBlogs = styled.div`
  column-gap: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  width: 60%;
  margin-bottom: 50px;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
  grid-template-rows: 1fr;
  grid-auto-columns: 0px;
  grid-auto-rows: 0px;
`;

const RelativeBlogTitle = styled.div`
  font-family: ${styleConstants.fontFamilyHeading};
  font-weight: 900;
  font-style: bold;
  font-size: 15px;
  color: ${styleConstants.colors.seaGreen};
  text-align: center;
  margin-bottom: 20px;
  flex: 1;
  @media only screen and (max-width: 650px) {
    font-size: 12px;
  }
  @media only screen and (max-width: 425px) {
    font-size: 10px;
  }
`;

const BlogCard = styled.div`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  display: flex;
  overflow: hidden;
  @media only screen and (max-width: 650px) {
    margin-left: 0;
    margin-bottom: 30px;
  }
  flex: 1;
`;

const BlogText = styled.div`
  font-family: ${styleConstants.fontFamilyHeading};
  font-weight: 500;
  font-style: bold;
  font-size: 15px;
  color: ${styleConstants.colors.seaGreen};
  margin: 15px 0;
  overflow: hidden;
  word-break: normal;
  width: 60%;
  padding-bottom: 50px;
  @media only screen and (max-width: 700px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 500px) {
    font-size: 14px;
  }
  border-bottom: 5px solid ${styleConstants.colors.salmon};
`;

const BlogCardWrapper = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  text-align: left;
  align-items: left;
  justify-content: flex-start;

  overflow: hidden;
  flex-wrap: wrap;
  width: 60%;
`;

const BlogCatagory = styled.div`
  font-family: ${styleConstants.fontFamilyHeading};
  font-weight: 900;
  font-style: bold;
  font-size: 15px;
  color: ${styleConstants.colors.white};
  margin: 5px;
  background-color: ${styleConstants.colors.salmon};
  padding: 5px;
  border-radius: 5px;
  @media only screen and (max-width: 700px) {
    font-size: 15px;
  }
  @media only screen and (max-width: 375px) {
    font-size: 12px;
  }
`;

const BlogTitle = styled.div`
  margin-top: 15px;
  display: flex;
  font-family: ${styleConstants.fontFamilyHeading};
  font-weight: 900;
  font-style: bold;
  font-size: 40px;
  color: ${styleConstants.colors.seaGreen};
  text-align: center;
  overflow: hidden;
  word-break: normal;
  width: 60%;
  margin-bottom: 10px;
  @media only screen and (max-width: 650px) {
    font-size: 25px;
  }
  @media only screen and (max-width: 425px) {
    font-size: 20px;
  }
`;

const BlogFoto = styled.img`
  width: 60%;
  max-height: 500px;
  border-radius: 5px;
`;

const BlogContainer = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
`;

const PageContainer = styled.div`
  margin-top: 200px;
  min-height: calc(100vh - 500px);
`;

const ErrorMessage = styled.div``;

export default BlogDetail;
