import React from "react";
import styled from "styled-components";
import { styleConstants } from "../../assets/style-contstants/style";
import { useHistory } from "react-router-dom";
import mock4 from "../../assets/images/stock/front1.jpg";
import mock5 from "../../assets/images/stock/front2.jpg";
import mock6 from "../../assets/images/stock/front3.jpg";

const Services: React.FunctionComponent = () => {
  const history = useHistory();

  const handleRoute = (url: string) => {
    history.push(url);
  };

  return (
    <ServiceContainer>
      <ServiceTitle>jouw volgende stap </ServiceTitle>
      <ServiceCardContainer>
        <ServiceCard onClick={() => handleRoute("/contact")}>
          <ServiceCardImage src={mock5}></ServiceCardImage>
          <ServiceTextContainer>
            <ServiceCardTitle>een nieuwe collega </ServiceCardTitle>
            <ServiceCardText>
              Ben jij op zoek naar een nieuwe (vaste of tijdelijke) collega? Of
              kun jij wat hulp gebruiken bij het opstellen van de vacaturetekst
              en promotie hiervan of wil jij meer weten over ons interim
              recruitmentadvies? Laat je gegevens achter via de contactpagina en
              wij vertellen jou graag vrijblijvend meer!
            </ServiceCardText>
          </ServiceTextContainer>
        </ServiceCard>
        <ServiceCard onClick={() => handleRoute("/contact")}>
          <ServiceCardImage src={mock4}></ServiceCardImage>
          <ServiceTextContainer>
            <ServiceCardTitle>een nieuwe baan</ServiceCardTitle>
            <ServiceCardText>
              Ben jij op zoek naar een nieuwe (vaste of tijdelijke) baan? Of kun
              jij wat hulp gebruiken bij het maken van jouw cv of motivatiebrief
              of wil jij meer weten over onze sollicitatietrainingen? Laat je
              gegevens achter via de contactpagina en wij vertellen jou graag
              vrijblijvend meer!
            </ServiceCardText>
          </ServiceTextContainer>
        </ServiceCard>

        <ServiceCard onClick={() => handleRoute("/contact")}>
          <ServiceCardImage src={mock6}></ServiceCardImage>
          <ServiceTextContainer>
            <ServiceCardTitle>informatie </ServiceCardTitle>
            <ServiceCardText>
              Wil jij graag meer weten over wat wij voor jou kunnen betekenen?
              Neem dan vrijblijvend contact met ons op en wij vertellen jou
              graag meer.
            </ServiceCardText>
          </ServiceTextContainer>
        </ServiceCard>
      </ServiceCardContainer>
    </ServiceContainer>
  );
};

const ServiceTextContainer = styled.div`
  flex-direction: column;
  display: flex;
  margin: 15px 0px;
  flex-wrap: wrap;
`;

const ServiceCardTitle = styled.div`
  font-family: ${styleConstants.fontFamilyHeading};
  font-style: bold;
  font-size: 25px;
  font-weight: 900;
  color: ${styleConstants.colors.seaGreen};
  line-height: 40px;

  @media only screen and (max-width: 700px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 800px) {
    font-size: 22px;
  }
`;

const ServiceCardText = styled.div`
  font-family: ${styleConstants.fontFamilyHeading};
  color: ${styleConstants.colors.seaGreen};
  font-size: 15px;
`;

const ServiceCardImage = styled.img`
  width: 100%;
  height: 250px;
  border-radius: 5px;
  @media only screen and (max-width: 700px) {
    max-width: 200px;
    max-height: 200px;
  }
`;

const ServiceCard = styled.div`
  flex-direction: column;
  display: flex;
  margin: 0 20px;
  margin-top: 15px;
  align-items: center;
  max-width: 300px;
  // box-shadow: 10px 5px 5px ${styleConstants.colors.darkerGrey};
  flex: 1;
  // background-color: ${styleConstants.colors.lightGreen};
  max-width: 300px;
  @media only screen and (max-width: 700px) {
    max-width: 200px;
    margin: 20px; 0px;
  }
`;

const ServiceCardContainer = styled.div`
  flex-direction: row;
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

const ServiceContainer = styled.div`
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  max-height: 100%;
  display: flex;
`;

const ServiceTitle = styled.div`
  display: flex;
  font-family: ${styleConstants.fontFamilyHeadingBold};
  font-style: bold;
  font-size: 60px;
  text-align: center;
  color: ${styleConstants.colors.seaGreen};
  line-height: 40px;
  margin: 175px 0 75px;
  @media only screen and (max-width: 700px) {
    font-size: 30px;
  }
  @media only screen and (max-width: 800px) {
    font-size: 40px;
  }
`;

export default Services;
