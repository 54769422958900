import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import { styleConstants } from "../../assets/style-contstants/style";
import {
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import Sealogo from "../../assets/logos/WZR_Logo_Trademark_White.png";

const Footer: React.FunctionComponent = () => {
  const handleRoute = (url: string) => {
    window.open(url);
  };

  return (
    <FooterContainer>
      <Row>
        <NavImage src={Sealogo} />
        <SecCol>
          <TextCol>
            <Heading>social links</Heading>
            <SocialItem
              onClick={() =>
                handleRoute(
                  "https://www.facebook.com/Wij-zijn-Rebels-113850303601525"
                )
              }
            >
              <Icon icon={faFacebookSquare} />
              facebook
            </SocialItem>
            <SocialItem
              onClick={() =>
                handleRoute("https://www.instagram.com/wijzijnrebels/")
              }
            >
              <Icon icon={faInstagram} />
              instagram
            </SocialItem>
            <SocialItem
              onClick={() =>
                handleRoute("https://www.linkedin.com/company/wijzijnrebels/")
              }
            >
              <Icon icon={faLinkedin} />
              linkedin
            </SocialItem>
            <SocialItem
              onClick={() => handleRoute("https://wa.me/31611762410")}
            >
              <Icon icon={faWhatsapp} />
              whatsApp
            </SocialItem>
            <SocialItem
              onClick={() => handleRoute(`mailto: ${"info@teamrebels.nl"}`)}
            >
              <Icon icon={faEnvelope} />
              mail
            </SocialItem>
          </TextCol>

          <TextCol>
            <Heading>handige links</Heading>
            <FooterLink href="/over-rebels">over rebels</FooterLink>
            <FooterLink href="/werken-bij">werken bij</FooterLink>
            <FooterLink href="/ons-team">ons team</FooterLink>
            <FooterLink href="/blogs">blogs</FooterLink>
            <FooterLink href="/contact">contact</FooterLink>
            <FooterLink href="/contact#faqs">meest gestelde vragen</FooterLink>
          </TextCol>
          <TextCol>
            <Heading>diensten</Heading>
            <FooterLink href="/werken-bij">een nieuwe collega</FooterLink>
            <FooterLink href="/werken-bij">een nieuwe baan</FooterLink>
            <FooterLink href="/">informatie</FooterLink>
          </TextCol>
          <TextCol>
            <Heading>contact</Heading>
            <MapsCol>
              <FooterLink
                onClick={() =>
                  handleRoute(
                    "http://maps.google.com/?q=Rietbaan 2, 2908 LP Capelle aan den IJssel"
                  )
                }
              >
                rietbaan 2 <br />
                2908 LP
                <br />
                capelle aan den IJssel
              </FooterLink>
              <FooterLink onClick={() => handleRoute("tel:+91123456789")}>
                085 0870993
              </FooterLink>
            </MapsCol>
          </TextCol>
        </SecCol>
      </Row>
    </FooterContainer>
  );
};

const MapsCol = styled.div`
  flex-direction: column;
  display: flex;

  flex: 1;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  height: 17px;
`;

const SecCol = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

const SocialItem = styled.div`
  flex-direction: row;
  font-family: ${styleConstants.fontFamilyHeading};
  font-style: bold;
  font-size: 18px;
  color: white;
  margin-bottom: 5px;
  &:hover {
    color: ${styleConstants.colors.salmon};
    transition: 200ms ease-in;
  }
  @media only screen and (max-width: 700px) {
    font-size: 14px;
  }
`;

const NavImage = styled.img`
  height: 120px;
  width: 120px;
  image-background: url(${Sealogo});
  margin-right: 25px;
  justify-content: center;
  margin-top: 20px;
  @media only screen and (max-width: 700px) {
    margin: 0;
  }
`;

const TextCol = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  font-size: 30px;
  flex-shrink: 0;
  margin-left: 50px;
  flex: 1;
  min-width: 150px;
  margin-bottom: 15px;
  @media only screen and (max-width: 700px) {
    margin-top: 20px;
  }
`;

const Row = styled.div`
  display: flex;
  flex: 1;
  padding: 25px;
  @media only screen and (max-width: 700px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const FooterLink = styled.a`
  color: #fff;
  margin-bottom: 5px;
  font-size: 18px;
  text-decoration: none;
  font-family: ${styleConstants.fontFamilyHeading};
  &:hover {
    color: pink;
    transition: 200ms ease-in;
  }
  @media only screen and (max-width: 700px) {
    font-size: 14px;
  }
`;

const Heading = styled.div`
  font-size: 24px;
  color: #fff;
  margin-bottom: 20px;
  font-family: ${styleConstants.fontFamilyHeading};
  font-weight: bold;
  @media only screen and (max-width: 700px) {
    font-size: 18px;
  }
`;

const FooterContainer = styled.div`
  background-color: ${styleConstants.colors.seaGreen};
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px 0;
  align-items: center;
  display: flex;
  bottom: 0;
  position: relative;
  margin-top: auto;
`;

export default Footer;
