import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Sealogo from "../../assets/logos/WZR_Logo_Full_SeaGreen.png";
import Lightlogo from "../../assets/logos/WZR_Logo_Full_White.png";
import { styleConstants } from "../../assets/style-contstants/style";

const Navbar: React.FunctionComponent = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [colorChange, setColorchange] = useState(false);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  const controlNavbar = () => {
    setToggleMenu(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  return (
    <>
      <NavbarContainter scrolling={colorChange}>
        <NavbarLogo to="/">
          <NavImage src={colorChange ? Lightlogo : Sealogo} />
        </NavbarLogo>
        {screenWidth < 700 && (
          <NavButton scrolling={colorChange} onClick={toggleNav}>
            <Icon scrolling={colorChange} icon={faBars} />
          </NavButton>
        )}
        {screenWidth > 700 ? (
          <NavbarList scrolling={colorChange}>
            <NavLink scrolling={colorChange} to="/over-rebels">
              over rebels
            </NavLink>
            <NavLink scrolling={colorChange} to="/werken-bij">
              werken bij
            </NavLink>
            <NavLink scrolling={colorChange} to="/blogs">
              blogs
            </NavLink>
            <NavLink scrolling={colorChange} to="/contact">
              contact
            </NavLink>
          </NavbarList>
        ) : (
          toggleMenu && (
            <NavbarList scrolling={colorChange}>
              <NavLink scrolling={colorChange} to="/over-rebels">
                over rebels
              </NavLink>
              <NavLink scrolling={colorChange} to="/werken-bij">
                werken bij
              </NavLink>
              <NavLink scrolling={colorChange} to="/blogs">
                blogs
              </NavLink>
              <NavLink scrolling={colorChange} to="/contact">
                contact
              </NavLink>
            </NavbarList>
          )
        )}
      </NavbarContainter>
    </>
  );
};

const NavImage = styled.img`
  height: 50px;
  image-background: url(${Sealogo});
  margin-right: 15px;
`;

type ScrollingProps = {
  scrolling: boolean;
};

const NavbarContainter = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 25px 40px;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${(props: ScrollingProps) =>
    props.scrolling
      ? styleConstants.colors.seaGreen
      : styleConstants.colors.lightGreen};

  z-index: 20;
  overflow: hidden;
  @media only screen and (max-width: 700px) {
    flex-direction: row;
    align-items: center;
    padding: 15px;
  }
`;

const NavbarList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 5px 0 15px;
    display: flex;
    list-style: none;
    background-color: ${(props: ScrollingProps) =>
      props.scrolling
        ? styleConstants.colors.seaGreen
        : styleConstants.colors.lightGreen};
    width: 100%;
    flex-direction: column;
    position: fixed;
    top: 65px;
    left: 0;
  }
`;

const NavLink = styled(Link)`
  color: ${(props: ScrollingProps) =>
    props.scrolling
      ? styleConstants.colors.white
      : styleConstants.colors.seaGreen};
  font-family: ${styleConstants.fontFamilyHeading};
  text-decoration: none;
  font-size: 20px;
  font-weight: 850;
  line-height: 18px;
  margin-right: 24px;
  @media only screen and (max-width: 700px) {
    margin: 10px 0 0 0;
    font-size: 10px;
  }
  @media only screen and (max-width: 1050px) {
    font-size: 20px;
  }
  -webkit-font-smoothing: antialiased;
`;

const NavbarLogo = styled(Link)`
  @media only screen and (max-width: 700px) {
    margin: 0px;
  }
  @media only screen and (max-width: 1050px) {
    margin: 0 25px;
  }
`;

const NavButton = styled.button`
  border: none;
  color: none;
  background-color: ${(props: ScrollingProps) =>
    props.scrolling
      ? styleConstants.colors.lightGreen
      : styleConstants.colors.seaGreen};
  text-align: center;
  margin-top: 15px;
`;

const Icon = styled(FontAwesomeIcon)`
  height: 25px;
  width: 25px;
  color: ${(props: ScrollingProps) =>
    props.scrolling
      ? styleConstants.colors.seaGreen
      : styleConstants.colors.lightGreen};
`;

export default Navbar;
