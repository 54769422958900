import "../../index.css";

export const styleConstants = {
  fontFamilyHeading: "Gellix",
  fontFamilyHeadingBold: "Gellix-Bold",
  colors: {
    black: "#000000",
    white: "#ffffff",
    lightGreen: "#D0E5E3",
    LighterGreen: "#E6F0F1",
    salmon: "#F5B5B4",
    seaGreen: "#00555C",
    lightGrey: "#EAEAEA",
    lighterGrey: "#f2f2f2",
    darkerGrey: "#e0e0e0",
  },
  headerHeight: 95,
  bottomTabHeight: 75,
};
