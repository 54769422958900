import React from "react";
import styled from "styled-components";
import { styleConstants } from "../../assets/style-contstants/style";
import Reviews from "../../components/reviews/reviews";
import mock1 from "../../assets/images/stock/rbls1.jpg";
import mock2 from "../../assets/images/stock/rbls2.jpg";
import mock3 from "../../assets/images/stock/rbls3.jpg";

const Rebels: React.FunctionComponent = () => {
  return (
    <>
      <PageContainer>
        <IntroBox>
          <Title>onze van Dale</Title>
          <IntroText2>re·bels – (bijvoeglijk naamwoord, bijwoord)</IntroText2>
          <IntroText>
            1 Een rebel is iemand met energie, humor en een flinke dosis lef.
            Iemand die nieuwsgierig én leergierig is en (met natuurlijk een
            beetje gezonde spanning en de juiste hulp) uit zijn comfort zone
            durft te stappen. Een rebel is iemand die doelgericht het beste uit
            zichzelf en zijn carrière wil halen en anderen hiermee motiveert en
            inspireert.
          </IntroText>
        </IntroBox>
        <InfoContainer>
          <MissionBox>
            <MissionImage src={mock2}></MissionImage>
            <MissionTitle>our mission</MissionTitle>
            <MissionText>
              Het is onze missie om jou met een glimlach naar jouw werk te laten
              gaan. Hier zetten wij ons elke dag met veel energie, doelgericht
              voor in. Wij geloven erin dat wij vandaag het verschil kunnen
              maken van jouw morgen.
              <br /> <br />
              Wij halen graag alles uit jou, jouw carrière en organisatie! Wij
              bieden alles voor jouw volgende stap, of je nou op zoek bent naar
              een nieuwe collega die jouw team komt versterken of jij zelf op
              zoek bent naar een nieuwe toffe uitdaging.
              <br /> <br />
            </MissionText>
          </MissionBox>
          <MissionBox>
            <MissionImage src={mock1}></MissionImage>
            <MissionTitle>net even anders</MissionTitle>
            <MissionText>
              Getting things done, wij hebben een slagingspercentage van 96%;
              <br /> <br /> Wij hebben een deskundig, enthousiast en flexibel
              officeteam dat klaarstaat voor onze Rebels;
              <br /> <br /> Wij zijn no nonsens, houden niet van kleine
              lettertjes en komen onze beloftes na;
              <br /> <br /> Going the extra mile with a smile, wij gaan nét even
              iets verder dan anderen;
              <br /> <br /> Wij denken in mogelijkheden, nee komt niet voor in
              ons woordenboek; <br /> <br /> Wij organiseren toffe events voor
              onze Rebels, gericht op teamwork en persoonlijke ontwikkeling.
            </MissionText>
          </MissionBox>

          <MissionBox>
            <MissionImage src={mock3}></MissionImage>
            <MissionTitle>ons team</MissionTitle>
            <MissionText>
              Ons officeteam bestaat uit deskundige, enthousiaste collega’s die
              verschillende rollen vervullen op HR, recruitment, en finance-
              gebied. <br /> <br />
              Wij zijn no nonsens, houden niet van kleine lettertjes en komen
              onze beloftes na. Wij gaan nét even iets verder dan de rest, wij
              geloven namelijk in going the extra mile with a smile. Wij denken
              in mogelijkheden, nee komt niet voor in ons woordenboek.
              <br /> <br /> Ontmoeten wij elkaar snel op één van onze toffe
              events?
            </MissionText>
          </MissionBox>
        </InfoContainer>

        <Reviews />
      </PageContainer>
    </>
  );
};

const Title = styled.div`
  font-family: ${styleConstants.fontFamilyHeadingBold};
  font-weight: 900;
  font-style: bold;
  font-size: 60px;
  line-height: 40px;
  color: ${styleConstants.colors.seaGreen};
  @media only screen and (max-width: 700px) {
    flex-direction: column;
    align-items: center;

    font-size: 40px;
  }
`;

const MissionImage = styled.img`
  width: 100%;
  height: 250px;
  border-radius: 5px;
  @media only screen and (max-width: 700px) {
    max-width: 200px;
    max-height: 200px;
  }
`;

const MissionText = styled.div`
  font-family: ${styleConstants.fontFamilyHeading};
  font-weight: 200;
  font-size: 16px;
  color: ${styleConstants.colors.seaGreen};
  margin-top: 10px;

  @media only screen and (max-width: 500px) {
    font-size: 12px;
  }
`;

const InfoContainer = styled.div`
  margin: 0px 90px 50px;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  @media only screen and (max-width: 1300px) {
    justify-content: center;
    margin: 0px 70px 50px;
  }
  @media only screen and (max-width: 500px) {
    font-size: 18px;
    margin: 0px 60px 50px;
  }
`;

const MissionBox = styled.div`
  max-width: 300px;
  margin: 50px 30px 20px;
`;

const MissionTitle = styled.div`
  font-family: ${styleConstants.fontFamilyHeadingBold};
  font-weight: 900;
  font-style: bold;
  font-size: 30px;
  line-height: 40px;
  color: ${styleConstants.colors.seaGreen};
  @media only screen and (max-width: 700px) {
    flex-direction: column;
    align-items: center;

    font-size: 40px;
  }
`;

const IntroBox = styled.div`
  padding-top: 175px;
  display: flex;
  flex-direction: column;
  background-color: ${styleConstants.colors.lightGreen};
  overflow: hidden;
  margin: 0;
  max-width: 100%;
  max-height: 100%;
  align-items: center;
  padding-bottom: 60px;
  overflow: hidden;
`;

const IntroText = styled.div`
  font-family: ${styleConstants.fontFamilyHeading};
  font-style: bold;
  font-size: 20px;
  color: ${styleConstants.colors.seaGreen};
  overflow-wrap: break-word;
  line-height: 30px;
  max-width: 800px;
  text-align: center;
  margin-top: 35px;
  @media only screen and (max-width: 700px) {
    flex-direction: column;
    align-items: center;
    padding: 25px;
    font-size: 20px;
  }
`;

const IntroText2 = styled.div`
  font-family: ${styleConstants.fontFamilyHeading};
  font-style: bold;
  font-size: 24px;
  font-weight: 900;
  color: ${styleConstants.colors.seaGreen};
  overflow-wrap: break-word;
  line-height: 30px;
  max-width: 800px;
  overflow: hidden;
  flex-wrap: wrap;
  text-align: center;
  margin-top: 40px;
  @media only screen and (max-width: 700px) {
    align-items: center;
    padding: 5px;
    font-size: 20px;
    overflow-wrap: break-word;
    overflow: hidden;
  }
`;

const PageContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 70px);
`;

export default Rebels;
