import React from "react";
import styled from "styled-components";
import { styleConstants } from "../../assets/style-contstants/style";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";

// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);

const Partners: React.FunctionComponent = () => {
  let test: any[] = [
    "https://s3-eu-west-1.amazonaws.com/zkn/files/dbaf1bc4faa84ec3fbc557303d35bd78.jpg",
    "https://hoogendoornprojectbeplanting.nl/wp-content/uploads/2020/07/Maandag_basislogo_groen_Digitaal_RGB.png",
    "https://pbs.twimg.com/profile_images/1362319592819986435/fOJD1qOR.jpg",
    "https://s3-eu-west-1.amazonaws.com/tpd/logos/50ab6c9300006400051eb5d5/0x0.png",
    "https://projectondernemers.nl/wp-content/uploads/2018/12/Logo-ProjectOndernemers.jpg",
    "https://gericall.nl/wp-content/themes/gericall/img/logo_1.svg",
    "https://govroam.nl/wp-content/uploads/2014/10/logo-gemeente-rotterdam.jpg",
    "https://www.uwv.nl/og_icon.png",
    "https://stormagency.nl/wp-content/uploads/2019/01/storm-agency-01.png",
    "https://cdn.cruisereizen.nl/img/favicons/cruisereizen-144x144.png",
    "https://mijnbuurtje.imgix.net/1904/77a69050-6030-11e9-a4fc-c73a1e5bf045.jpg?h=1782&w=1782",
  ];

  return (
    <>
      <TempCon>
        <Title>wij werkten eerder met </Title>
        <Swiper
          loop={true}
          autoplay={{
            delay: 2800,
            disableOnInteraction: false,
          }}
          breakpoints={{
            "@0.00": {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            "@0.75": {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            "@1.00": {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            "@1.50": {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            "@1.80": {
              slidesPerView: 5,
              spaceBetween: 15,
            },
            "@3.80": {
              slidesPerView: 5,
              spaceBetween: 15,
            },
          }}
          onSlideChange={() => {}}
          onSwiper={(swiper: any) => console.log(swiper)}
        >
          {test.map((item, index) => (
            <SwiperSlide key={item} virtualIndex={index}>
              {<Test3 src={item}>{item.title}</Test3>}
            </SwiperSlide>
          ))}
        </Swiper>
      </TempCon>
    </>
  );
};

const Title = styled.div`
  font-family: ${styleConstants.fontFamilyHeadingBold};
  font-weight: 900;
  font-style: bold;
  font-size: 60px;
  color: ${styleConstants.colors.seaGreen};
  text-align: center;
  margin-bottom: 80px;
  overflow: hidden;
  @media only screen and (max-width: 700px) {
    font-size: 30px;
  }
  @media only screen and (max-width: 800px) {
    font-size: 40px;
  }
`;

const TempCon = styled.div`
  background-color: ${styleConstants.colors.white};
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 120px 30px 80px;
`;

const Test3 = styled.img`
  max-width: 260px;
  height: 160px;
`;

export default Partners;
