import React, { useState } from "react";
import styled from "styled-components";
import { styleConstants } from "../../assets/style-contstants/style";

const Faqs: React.FunctionComponent = () => {
  const [clicked, setClicked] = useState(null);

  const toggle = (index: any) => {
    if (clicked === index) {
      //if clicked question is already active, then close it
      return setClicked(null);
    }

    setClicked(index);
  };

  const data = [
    {
      title: "Wat doet Rebels?",
      content:
        " Wij zijn het recruitmentbureau dat alles biedt voor jouw volgende stap. En doen dit zoals onze bedrijfsnaam al een beetje verraad, nét even anders.",
    },
    {
      title: "Ik zoek een nieuwe collega ",
      content:
        "Ben jij op zoek naar een nieuwe (vaste of tijdelijke) collega? Of kun jij wat hulp gebruiken bij het opstellen van de vacaturetekst en promotie hiervan? Of wil jij meer weten over ons interim recruitmentadvies? Laat nu je gegevens achter op onze contactpagina of bel naar 085 087 0993 en dan spreken wij elkaar snel!  ",
    },
    {
      title: "Ik zoek een baan",
      content:
        "Ben jij op zoek naar een nieuwe (vaste of tijdelijke) baan? Of kun jij wat hulp gebruiken bij het maken van jouw cv of motivatiebrief? Of wil jij meer weten over onze sollicitatietrainingen? Laat nu je gegevens achter op onze contactpagina of bel naar 085 087 0993 en dan spreken wij elkaar snel!  ",
    },
    {
      title: "Hoe kom ik in contact? ",
      content:
        "Wil jij graag meer weten over wat wij voor jou kunnen betekenen? Neem dan vrijblijvend contact met ons op en wij vertellen jou graag meer. ",
    },
  ];

  return (
    <>
      <Title>meest gestelde vragen</Title>
      <AccordionSection>
        <Container>
          {data.map((item, index) => {
            return (
              <>
                <AccordionItem onClick={() => toggle(index)}>
                  <AccordionTitle>{item.title}</AccordionTitle>
                  {clicked === index ? (
                    <Dropdown>
                      <p>{item.content}</p>
                    </Dropdown>
                  ) : null}
                </AccordionItem>
              </>
            );
          })}
        </Container>
      </AccordionSection>
    </>
  );
};

const Title = styled.div`
  margin-top: 100px;
  font-family: ${styleConstants.fontFamilyHeadingBold};
  font-weight: 900;
  font-style: bold;
  font-size: 40px;
  color: ${styleConstants.colors.seaGreen};
  line-height: 40px;
  text-align: center;
`;

const AccordionSection = styled.div`
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div``;

const Dropdown = styled.div`
  color: ${styleConstants.colors.seaGreen};
  font-size: 18px;
  font-family: ${styleConstants.fontFamilyHeading};
`;

const AccordionItem = styled.div`
  border: 3px solid ${styleConstants.colors.lightGreen};
  border-radius: 5px;
  margin: 15px;
  column-break-inside: avoid;
  padding: 25px;
  min-width: 650px;
  &:hover {
    background-color: ${styleConstants.colors.lightGreen};
    transition: 200ms ease-in;
  }
  &:hover {
    background-color: ${styleConstants.colors.lightGreen};
  }
  max-width: 650px;
  @media only screen and (max-width: 750px) {
    min-width: 0px;
  }
`;

const AccordionTitle = styled.div`
  font-family: ${styleConstants.fontFamilyHeading};
  color: ${styleConstants.colors.seaGreen};
  font-style: bold;
  font-size: 20px;
  margin-top: 5px;
  font-weight: 800;
`;

export default Faqs;
