import React, { useRef, useState } from "react";
import styled from "styled-components";
import { styleConstants } from "../../assets/style-contstants/style";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import mark from "../../assets/images/review/mark.png";
import irene from "../../assets/images/review/irene.png";
import judith from "../../assets/images/review/judith.jpg";
import nathalie from "../../assets/images/review/nathalie.jpg";
import rene from "../../assets/images/review/rene.jpg";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);

const Reviews: React.FunctionComponent = () => {
  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null);

  let test: any[] = [
    {
      name: "René van Hattem",
      text: "Rebels geeft het recruitmentproces binnen onze organisatie vorm door zelf daarin de lead te nemen. Dat doen ze met een wervelend enthousiasme, een grote gedrevenheid en oog voor detail. Daarnaast hebben ze aangename persoonlijkheden met een grote gunfactor, ‘getting things done’ past hun uitstekend! ",
      foto: rene,
      title: "Directeur en eigenaar GeriCall ",
    },
    {
      name: "Mark van de Leur",
      text: "Wat een verademing om met Rebels samen te werken. Door Covid-19 heb ik mij beseft dat ik een andere koers wil varen m.b.t tot mijn activiteiten als ondernemer. De gesprekken en coachingsessies die ik met Miranda gehad heb waren een enorme eyeopener. We hadden meteen een klik en ik merkte dat de informatie die in mij zat er heel gemakkelijk uit kwam. Dat zegt veel over Miranda. Ze heeft mij geholpen om mijzelf krachtig te presenteren en zaken in perspectief te plaatsen. We hebben uiteindelijk alles vertaald in een concreet plan van aanpak. Wij zijn Rebels daarnaast is de perfecte partij voor het matchen van de juiste werkgevers aan werknemers en andersom. Heb je iemand nodig voor vacatures binnen je bedrijf neem contact op met hun! Durf te investeren in jezelf of je organisatie, zoals ik nu ook gedaan heb.",
      foto: mark,
      title: " Ondernemer ",
    },
    {
      name: "Judith Simon- Emaus ",
      text: "Rebels heb ik ingeschakeld voor een werving en selectie binnen onze organisatie.  De collega’s van Rebels zijn deskundig, schakelen snel en pakken het wervingsproces gedegen aan. Ze luisteren aandachtig naar de wensen van de opdrachtgever. Ze hebben mensenkennis en ervaring waardoor zij in staat zijn een match te maken die aansluit bij het gevraagde profiel. De wijze waarop Rebels met de kandidaten omgaat verdiend een pluim. Ze wekken vertrouwen en begeleiden de kandidaten met veel energie, duidelijkheid en positiviteit. Ze doen echt net even dat beetje extra wat het verschil maakt. Rebels en haar ervaring in de recruitmentbranche weten wat nodig is om zowel de kandidaten als de opdrachtgevers meer dan tevreden te stellen. ",
      foto: judith,
      title: "Directeur Mediationfederatie Nederland (MfN- register) ",
    },
    {
      name: "Irene van Os",
      text: "De begeleiding, het contact en de communicatie met Wij zijn Rebels heb ik echt als heel fijn en prettig ervaren, ik wist precies waar ik aan toe was. Ook de tips die ze gaven waren heel waardevol en hebben mij super goed geholpen in het sollicitatie proces. Miranda heeft echt veel mensenkennis en weet al snel wat bij je past en wat niet, we hebben dan ook goede gesprekken gehad. Kortom, ik heb een hele positieve ervaring bij Wij zijn Rebels zo weer benaderen voor het vinden van een (nieuwe) baan.",
      foto: irene,
      title: "Gemeentesecretaris ",
    },

    {
      name: "Nathalie Renaers",
      text: "Ik ben met Rebels in contact gekomen via Instagram. We zijn aan de praat geraakt en ik had voor het eerst sinds lange tijd weer het gevoel dat ik écht begrepen werd en iemand naar mij luisterde. De coachingsessies en opdrachten van Rebels hebben mij ontzettend geholpen. Je krijgt alle nodige begeleiding, steun, motivatie en tips. Ik kan Rebels bij iedereen aanbevelen, neem zeker contact op om te kijken wat ze voor jou kunnen doen. Voor mij was het de beste beslissing die ik in lange tijd heb genomen. ",
      foto: nathalie,
      title: "Werkzoekend",
    },
  ];

  return (
    <>
      <Title>wat zeggen anderen</Title>
      <Test
        navigation={{ prevEl: prevEl, nextEl: nextEl }}
        loop={true}
        observeParents={true}
        observer={true}
        pagination={true}
        autoHeight={true}
        slidesPerView={1}
        spaceBetween={30}
        onSlideChange={() => {}}
        onSwiper={(swiper: any) => {
          console.log(swiper);
        }}
      >
        <ButtonPrev ref={(node) => setPrevEl(node)}>
          <Icon icon={faAngleLeft} />
        </ButtonPrev>
        <ButtonNext ref={(node) => setNextEl(node)}>
          <Icon icon={faAngleRight} />
        </ButtonNext>
        {test.map((item) => (
          <SwiperSlide id={item.title}>
            <ReviewItem>
              <ReviewWrapper>
                <ReviewPhoto src={item.foto} />
                <ReviewName>{item.name}</ReviewName>
                <ReviewTitle>{item.title}</ReviewTitle>
              </ReviewWrapper>
              <ReviewsText>{item.text}</ReviewsText>
            </ReviewItem>
          </SwiperSlide>
        ))}
      </Test>
    </>
  );
};

const Icon = styled(FontAwesomeIcon)`
  font-size: 40px;
  color: ${styleConstants.colors.lightGreen};
  size: sm;
  &:hover {
    color: ${styleConstants.colors.salmon};
    transition: 200ms ease-in;
  }
`;

const ButtonNext = styled.div`
  position: absolute;
  right: -5px;
  top: 50%;
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonPrev = styled.div`
  position: absolute;
  top: 50%;
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  left: -5px;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ReviewTitle = styled.div`
  font-family: ${styleConstants.fontFamilyHeading};
  font-weight: 400;
  font-style: bold;
  font-size: 16px;
  color: ${styleConstants.colors.seaGreen};
  text-align: center;
  @media only screen and (max-width: 700px) {
    font-size: 15px;
  }
`;

const ReviewWrapper = styled.div`
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 30px;
  @media only screen and (max-width: 1200px) {
    margin: 0 0 15px 0;
  }
  margin-right: 0px;
`;

const ReviewPhoto = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 75px;
  @media only screen and (max-width: 700px) {
    width: 120px;
    height: 120px;
  }
`;

const ReviewName = styled.div`
  font-family: ${styleConstants.fontFamilyHeading};
  font-weight: 900;
  font-style: bold;
  font-size: 18px;
  color: ${styleConstants.colors.seaGreen};
  text-align: center;
  @media only screen and (max-width: 700px) {
    font-size: 18px;
  }
`;

const Test = styled(Swiper)`
  margin-bottom: 40px;
  align-items: center;
  justify-content: center;
  max-width: 800px;
`;

const ReviewsText = styled.div`
  font-family: ${styleConstants.fontFamilyHeading};
  font-weight: 300;
  font-style: bold;
  font-size: 16px;
  color: ${styleConstants.colors.seaGreen};
  text-align: center;
  padding: 0px 15px;
  overflow: hidden;
  @media only screen and (max-width: 700px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 450px) {
    font-size: 12px;
  }
`;

const ReviewItem = styled.div`
  border: 3px solid ${styleConstants.colors.lightGreen};
  border-radius: 5px;
  padding: 25px;
  margin: 0 25px 35px;
  &:hover {
    background-color: ${styleConstants.colors.lightGreen};
    transition: 200ms ease-in;
  }
  &:hover {
    background-color: ${styleConstants.colors.lightGreen};
  }

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 700px) {
    padding: 15px;
  }
`;

const Title = styled.div`
  font-family: ${styleConstants.fontFamilyHeadingBold};

  font-style: bold;
  font-size: 60px;
  color: ${styleConstants.colors.seaGreen};
  text-align: center;
  margin-bottom: 20px;
  @media only screen and (max-width: 700px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 600px) {
    font-size: 30px;
  }
`;

export default Reviews;
