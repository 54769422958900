import React from "react";
import styled from "styled-components";
import { styleConstants } from "../../assets/style-contstants/style";
import Partners from "../../components/partners/partners";
import Services from "../../components/services/services";

const Home: React.FunctionComponent = () => {
  return (
    <>
      <HomeContainer>
        <Services />
        <Partners />
      </HomeContainer>
    </>
  );
};

const HomeContainer = styled.div`
  background-color: ${styleConstants.colors.white};
  width: 100%;
  min-height: calc(100vh - 300px);
`;

export default Home;
