import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { styleConstants } from "../../assets/style-contstants/style";
import { BlogItem } from "../../typings/blogItem";
import { useHistory } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import db from "../../firebase";

const Blog: React.FunctionComponent = () => {
  const history = useHistory();
  const [blogs, setBlogs] = useState<BlogItem[]>();

  const internalRoute = (url: string, data: BlogItem) => {
    history.push({
      pathname: url,
      state: data,
    });
  };

  const fetchBlogs = async () => {
    const querySnapshot = await getDocs(collection(db, "blogs"));
    let temp: any[] = [];
    querySnapshot.forEach((doc) => {
      temp.push(doc.data());
    });
    setBlogs(temp);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <>
      <PageContainer>
        <Title>blogs</Title>
        <BlogContainer>
          {blogs != null ? (
            blogs.map((data: BlogItem) => {
              return (
                <BlogCard
                  key={data.id}
                  onClick={() => internalRoute(`/blog/${data.id}`, data)}
                >
                  <BlogFoto src={data.photo} />
                  <BlogCardWrapper>
                    {data.tags.map((doc) => {
                      return <BlogCatagory>{doc}</BlogCatagory>;
                    })}
                  </BlogCardWrapper>
                  <BlogTitle>{data.title}</BlogTitle>
                </BlogCard>
              );
            })
          ) : (
            <ErrorMessage>loading</ErrorMessage>
          )}
        </BlogContainer>
      </PageContainer>
    </>
  );
};

const ErrorMessage = styled.div``;

const BlogCardWrapper = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 5px;
`;

const BlogCatagory = styled.div`
  display: flex;
  font-family: ${styleConstants.fontFamilyHeading};
  font-weight: 900;
  font-style: bold;
  font-size: 14px;
  color: ${styleConstants.colors.white};
  background-color: ${styleConstants.colors.salmon};
  padding: 5px;
  border-radius: 5px;
  margin-left: 5px;
`;

const Title = styled.div`
  font-family: ${styleConstants.fontFamilyHeadingBold};
  font-style: bold;
  font-size: 60px;
  text-align: center;
  color: ${styleConstants.colors.seaGreen};
  line-height: 40px;
  @media only screen and (max-width: 1000px) {
    font-size: 50px;
  }
  @media only screen and (max-width: 850px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 650px) {
    font-size: 30px;
  }
`;

const BlogTitle = styled.div`
  font-family: ${styleConstants.fontFamilyHeading};
  font-weight: 900;
  font-style: bold;
  font-size: 20px;
  color: ${styleConstants.colors.seaGreen};
  text-align: center;
  @media only screen and (max-width: 650px) {
    font-size: 15px;
  }
`;

const BlogFoto = styled.img`
  width: 100%;
  max-height: auto;
  border-radius: 5px;
  flex: 1;

  overflow: hidden;
`;

const BlogCard = styled.div`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 300px;
  margin-left: 25px;
  margin-bottom: 50px;
  display: flex;
  overflow: hidden;
  @media only screen and (max-width: 650px) {
    margin-left: 0;
    margin-bottom: 30px;
  }
`;

const BlogContainer = styled.div`
  margin: 50px 50px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 18rem);
  justify-content: center;

  flex-wrap: wrap;
`;

const PageContainer = styled.div`
  margin-top: 175px;
  min-height: calc(100vh - 500px);
`;

export default Blog;
