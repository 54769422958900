import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { styleConstants } from "../../assets/style-contstants/style";
import {
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Faqs from "../../components/faqs/faqs";
import emailjs from "@emailjs/browser";

const Contact: React.FunctionComponent = () => {
  const [values, setValues] = useState({
    name: "",
    surname: "",
    email: "",
    telephone: "",
    profession: "",
    title: "",
    text: "",
  });
  const [status, setStatus] = useState("");

  const handleSubmit = () => {
    emailjs
      .send(
        "service_rgcig8e",
        "template_7aftqm8",
        values,
        "user_PHF1MR6mJXGQKL7EhfkPU"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response);
          renderAlert();
          setValues({
            name: "",
            surname: "",
            email: "",
            telephone: "",
            profession: "",
            title: "",
            text: "",
          });
          setStatus("SUCCESS");
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );
  };

  const renderAlert = () => window.alert("je bericht is succesvol verzonden!");

  const handleChange = (target: any, value: any) => {
    setValues((values) => ({
      ...values,
      [target]: value,
    }));
  };

  useEffect(() => {
    if (status === "SUCCESS") {
      setTimeout(() => {
        setStatus("");
      }, 3000);
    }
  }, [status]);

  const handleRoute = (url: string) => {
    window.open(url);
  };

  return (
    <>
      <PageContainer>
        <Title>contactpagina</Title>
        <ContactWrapper>
          <FormContainer>
            <HorizontalGroup>
              <FormGroup>
                <FormTitle>Voornaam * </FormTitle>
                <FormInput
                  required={true}
                  value={values.name}
                  onChange={(event) => handleChange("name", event.target.value)}
                ></FormInput>
              </FormGroup>
              <FormGroup>
                <FormTitle>Achternaam *</FormTitle>
                <FormInput
                  required={true}
                  value={values.surname}
                  onChange={(event) =>
                    handleChange("surname", event.target.value)
                  }
                ></FormInput>
              </FormGroup>
            </HorizontalGroup>
            <FormGroup>
              <FormTitle>Email *</FormTitle>
              <FormInput
                required={true}
                value={values.email}
                onChange={(event) => handleChange("email", event.target.value)}
              ></FormInput>
            </FormGroup>
            <FormGroup>
              <FormTitle>Telefoonnummer *</FormTitle>
              <FormInput
                required={true}
                value={values.telephone}
                onChange={(event) =>
                  handleChange("telephone", event.target.value)
                }
              ></FormInput>
            </FormGroup>
            <FormGroup>
              <FormTitle>Ik ben</FormTitle>
              <FormSelect
                value={values.profession}
                onChange={(event) =>
                  handleChange("profession", event.target.value)
                }
              >
                <option value="" hidden></option>
                <option value="een professional">Een professional</option>
                <option value="een opdrachtgever">Een opdrachtgever</option>
                <option value="overig">Overig</option>
              </FormSelect>
            </FormGroup>
            <FormGroup>
              <FormTitle>Onderwerp *</FormTitle>
              <FormInput
                required={true}
                value={values.title}
                onChange={(event) => handleChange("title", event.target.value)}
              ></FormInput>
            </FormGroup>
            <FormGroup>
              <FormTitle>Neem met mij contact op over *</FormTitle>
              <FormInputBig
                required={true}
                value={values.text}
                onChange={(event) => handleChange("text", event.target.value)}
              ></FormInputBig>
            </FormGroup>
            <ButtonContainer>
              <ContactButton onClick={() => handleSubmit()}>
                Versturen
              </ContactButton>
            </ButtonContainer>
          </FormContainer>
          <ContactContainer>
            <>
              <ContactTitle>Lets talk</ContactTitle>

              <SocialItem onClick={() => handleRoute("tel:+91123456789")}>
                <Icon icon={faPhone} />
                085 0870993
              </SocialItem>
              <SocialItem
                onClick={() => handleRoute(`mailto: ${"info@teamrebels.nl"}`)}
              >
                <Icon icon={faEnvelope} />
                Info@teamrebels.nl
              </SocialItem>
              <ContactTitle>Connect with us on social media</ContactTitle>
              <SocialRow>
                <SocialItem
                  onClick={() =>
                    handleRoute(
                      "https://www.linkedin.com/company/wijzijnrebels/"
                    )
                  }
                >
                  <Icon icon={faLinkedin} />
                  Linkedin
                </SocialItem>
                <SocialItem
                  onClick={() =>
                    handleRoute("https://www.instagram.com/wijzijnrebels/")
                  }
                >
                  <Icon icon={faInstagram} />
                  Instagram
                </SocialItem>
                <SocialItem
                  onClick={() => handleRoute("https://wa.me/31611762410")}
                >
                  <Icon icon={faWhatsapp} />
                  WhatsApp
                </SocialItem>
                <SocialItem
                  onClick={() =>
                    handleRoute(
                      "https://www.facebook.com/Wij-zijn-Rebels-113850303601525"
                    )
                  }
                >
                  <Icon icon={faFacebookSquare} />
                  Facebook
                </SocialItem>
              </SocialRow>
            </>
            <Maps id="faqs">
              <Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2458.7146291898403!2d4.575645316322831!3d51.95739518552128!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5cd6658882555%3A0x6e661c12049be589!2sRietbaan%202%2C%202908%20LP%20Capelle%20aan%20den%20IJssel!5e0!3m2!1sen!2snl!4v1639401882832!5m2!1sen!2snl"></Iframe>
            </Maps>
          </ContactContainer>
        </ContactWrapper>

        <Faqs />
      </PageContainer>
    </>
  );
};

const ContactButton = styled.button`
  background: ${styleConstants.colors.seaGreen};
  padding: 15px 30px;
  font-size: 11px;
  color: ${styleConstants.colors.lightGreen};
  border-radius: 5px;
  border-style: none;
  font-family: ${styleConstants.fontFamilyHeading};
  text-decoration: none;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 18px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 300px;
  loading: lazy;
  border-radius: 5px;
  border: none;
  @media (max-width: 850px) {
    height: 250px;
  }
`;

const Maps = styled.div`
  margin-top: 70px;
  @media (max-width: 850px) {
    margin-top: 25px;
  }
`;

const FormSelect = styled.select`
  padding: 12px 20px;
  border: none;
  font-family: ${styleConstants.fontFamilyHeading};
  color: ${styleConstants.colors.seaGreen};
  font-size: 15px;
  border-radius: 5px;
  background-color: white;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  height: 17px;
  color: ${styleConstants.colors.lightGreen};
`;

const SocialRow = styled.div`
  flex-direction: column;
  display: flex;
`;

const ContactTitle = styled.div`
  font-family: ${styleConstants.fontFamilyHeading};
  font-weight: 900;
  font-style: bold;
  font-size: 20px;
  color: ${styleConstants.colors.white};
  text-align: center;
  margin: 20px 0 20px;
  word-break: break-word;
  flex-wrap: wrap;
`;

const ContactWrapper = styled.div`
  display: table;
  align-items: center;
  height: 100%;
  flex-wrap: wrap;
  border-collapse: collapse;
  margin: 15px;
  padding: 10px;
  @media (max-width: 850px) {
    border-radius: 5px;
    overflow: hidden;
  }
`;

const ContactContainer = styled.div`
  background-color: ${styleConstants.colors.seaGreen};
  display: table-cell;
  padding: 20px;
  border-radius: 0px 5px 5px 0px;
  justify-content: space-between;
  box-shadow: 10px 5px 5px ${styleConstants.colors.darkerGrey};
  flex: 1;
  @media (max-width: 850px) {
    border-radius: 0px;
  }
`;

const HorizontalGroup = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  @media (max-width: 584px) {
    flex-direction: column;
    width: 100%;
  }
`;

const FormContainer = styled.div`
  justify-content: center;
  flex-direction: column;
  background-color: ${styleConstants.colors.lightGreen};
  margin-top: 20px;
  display: table-cell;
  @media (max-width: 584px) {
    flex-direction: column;
  }

  border-radius: 5px 0px 0px 5px;
  box-shadow: 10px 5px 5px ${styleConstants.colors.darkerGrey};
  @media (max-width: 850px) {
    display: table-row;
  }
`;

const FormGroup = styled.div`
  margin-top: 30px;
  flex-direction: column;
  display: flex;
  margin: 15px;
  flex-wrap: wrap;
  overflow: hidden;
`;

const FormTitle = styled.label`
  margin-bottom: 5px;
  font-family: ${styleConstants.fontFamilyHeading};
  font-weight: 900;
  font-style: bold;
  font-size: 15px;
  color: ${styleConstants.colors.seaGreen};
`;

const FormInput = styled.input`
  padding: 12px 20px;
  border: none;
  font-family: ${styleConstants.fontFamilyHeading};
  color: ${styleConstants.colors.seaGreen};
  font-size: 15px;
  border-radius: 5px;
`;

const FormInputBig = styled.textarea`
  padding: 12px 20px;
  border: none;
  height: 150px;
  resize: vertical;
  max-height: none;
  overflow: hidden;
  font-family: ${styleConstants.fontFamilyHeading};
  color: ${styleConstants.colors.seaGreen};
  font-size: 15px;
  border-radius: 5px;
`;

const PageContainer = styled.div`
  margin: 175px 0;
  flex-direction: column;
  align-items: center;
  display: flex;
  min-height: calc(100vh - 500px);
`;

const Title = styled.div`
  font-family: ${styleConstants.fontFamilyHeadingBold};
  font-style: bold;
  font-size: 60px;
  text-align: center;
  color: ${styleConstants.colors.seaGreen};
  line-height: 40px;
  text-align: center;
  margin-bottom: 30px;
`;

const SocialItem = styled.div`
  flex-direction: row;
  font-family: ${styleConstants.fontFamilyHeading};
  font-style: bold;
  font-size: 15px;
  color: white;
  margin-top: 10px;
  &:hover {
    color: ${styleConstants.colors.salmon};
    ${Icon} {
      color: ${styleConstants.colors.salmon};
    }
  }
`;

export default Contact;
